import React from 'react'
import { Footer, Possibility, Features, Header, WhatDT} from './containers';
import { CTA, Brand, Navbar } from './components';
import './App.css';



function App() {
  
  return (
    <div className='App'>
      <div className='gradient__bg'>
        <Navbar />
        <Header />
        <Brand />
        <WhatDT />
        <Features />
        <Possibility />
        <CTA />
        <Footer />
      </div>
    </div>
  );
}

export default App