import React, {useState, useRef} from 'react';
import './footer.css';
import { UilMapMarker } from '@iconscout/react-unicons';
import dtLogo from '../../assets/logo.png';
import { useTranslation } from "react-i18next";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import emailjs from 'emailjs-com';
import toast from 'react-hot-toast';

const Footer = () => {
  const [phoneValue, setphoneValue] = useState()
  const { t } = useTranslation();
  const form = useRef();
  const callbackRef = useRef();


  const requestCallback = (e) => {
    e.preventDefault();
    const refreshToast = toast.loading('Refreshing..');

    emailjs.sendForm('service_aqpzmtp', 'template_cpv0l5n', form.current, 'tQTn_oV9W-qDm-nv2')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
      callbackRef.current.value = '';


    toast.success('Request successfully sent', {
      id: refreshToast
    });

  };
  return (
    <div className='dt__footer section__padding'>
      <div className='dt__footer-heading'>
        <h1 className='gradient__text'>{t('call_sales')}</h1>

      </div>
      <form ref={form} onSubmit={requestCallback}>
        <div className='dt__footer-phone_input'>
          <PhoneInput
        placeholder={t('phone')}
        value={phoneValue}
        onChange={setphoneValue}
        ref={callbackRef}
        name='phonenumber'
        required />
        </div>
      <div className='dt__footer-btn'>
        <button type='submit'>{t('request_call')}</button>
      </div>
      </form>
      <div className='dt__footer-links'>
        <div className='dt__footer-links_logo'>
          <img src={dtLogo} alt="Digital Trendz" />
          <p><span>Business Intelliegence Agency.</span></p>
          <br />
          <p><span><UilMapMarker size="16" color="#161840" /> {t('usa')}</span> 201 Spear Street, Suite 1100 San Francisco, CA.</p>
          <p><span><UilMapMarker size="16" color="#161840" /> {t('france')}</span> 60 Rue François 1er - 75008 Paris.</p>
          <p><span><UilMapMarker size="16" color="#161840" /> {t('algeria')} </span> 01 Rue Ahmed OUAKED, Dely Brahim.</p>

        </div>
        <div className='dt__footer-links_div'>
          <h4>{t('company')}</h4>
          <a href="#about"><p>{t('about')}</p></a>
          <a href="#services"><p>{t('services')}</p></a>
          <a href="#solutions"><p>{t('solutions')}</p></a>
          <a href="#cases"><p>{t('case_study')}</p></a>


        </div>
        <div className='dt__footer-links_div'>
          <h4>Services</h4>
          <p>{t('data_services')}</p>
          <p>{t('marketing_services')}</p>
          <p>{t('market_research')}</p>


        </div>
        <div className='dt__footer-links_div'>
          <h4>Solutions</h4>
          <p>LocalEasy</p>
          <p>FitPeddle</p>
          <p>BigData</p>
          

        </div>
        <div className='dt__footer-links_div'>
          <h4>{t('follow_us')}</h4>
          <a href="https://facebook.com/BIDigitalTrendz"><p>Facebook</p></a>
          <a href="https://instagram.com/bidigitaltrendz"><p>Instagram</p></a>
          <a href="https://linkedin.com/company/bidigitaltrendz"><p>LinkedIn</p></a>
          <a href="https://twitter.com/DigitalTrendz4"><p>Twitter</p></a>
          


        </div>
        
        

      </div>
      <div className='dt__footer-copyright'><p>&copy; 2022 Digital Trendz. {t('rights')} </p></div>
    </div>
  )
}

export default Footer