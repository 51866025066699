import React from 'react';
import Feature from '../../components/feature/Feature';
import { useTranslation } from "react-i18next";
import './whatdt.css';

function WhatDT() {
  const { t } = useTranslation();
  return (
    <div className='dt__whatdt section__margin' id='about'>
      <div className='dt__whatdt-feature'>
        <Feature title={t('about_dt')} text={t('about_text')} />
      </div>
      <div className='dt__whatdt-heading'>
        <h1 className='gradient__text'>{t('about_header')}</h1>
        
      </div>
      <div className='dt__whatdt-container'>
        <Feature title={t('our_team')} text={t('our_team_text')} />
        <Feature title={t('our_approach')} text={t('our_approach_text')} />
        <Feature title={t('our_values')} text={t('our_values_text')} />
      </div>
    </div>
  );
}

export default WhatDT