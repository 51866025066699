import React from 'react';
import Feature from '../../components/feature/Feature';
import { useTranslation } from "react-i18next";
import './features.css';



const Features = () => {
  const { t } = useTranslation();
  return (
    <div className='dt__features section__padding' id='services'>
      <div className='dt__features-heading'>
        <h1 className='gradient__text'>{t('what_we_do')}</h1>
        <p>{t('what_we_do_text')}</p>
      </div>
      <div className='dt__features-container'>
        
          <Feature title={t('data_services')} text={t('data_services_text')} />
          <Feature title={t('marketing_services')} text={t('marketing_services_text')} />
          <Feature title={t('market_research')} text={t('market_research_text')} />
       
      </div>
    </div>
  )
}

export default Features