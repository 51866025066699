import React from 'react';
import PossibilityImage from '../../assets/PossibilityImage.png';
import arrow from '../../assets/arrow.svg';
import { useTranslation } from "react-i18next";
import './possibility.css';


const Possibility = () => {
  const { t } = useTranslation();
  return (
    <div className='dt__possibility section__padding' id='solutions'>
      <div className='dt__possibility-image'>
        <img src={PossibilityImage} alt="Solutions"/>
      </div>
      <div className='dt__possibility-content'>
        <h4>{t('get_early_access')}</h4>
        <h1 className='gradient__text'>{t('solutions_h1')}</h1>
        <p>{t('solutions_text')}</p>
        
        <h4><img src={arrow} alt='arrow' />{t('explore')}</h4>

      </div>

    </div>
  )
}

export default Possibility