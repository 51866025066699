import React from 'react';
import './brand.css';
import {brand1,
  brand2,
  brand3,
  brand4,
  brand5,
  brand6,
  brand7,
  brand8,} from './imports';

const Brand = () => {
  return (
    <div className='dt__brand secton__padding'>
      <div>
        <img src={brand1} alt='brand' />
      </div>
      <div>
        <img src={brand2} alt='brand' />
      </div>
      <div>
        <img src={brand3} alt='brand' />
      </div>
      <div>
        <img src={brand4} alt='brand' />
      </div>
      <div>
        <img src={brand5} alt='brand' />
      </div>
      <div>
        <img src={brand6} alt='brand' />
      </div>
      <div>
        <img src={brand7} alt='brand' />
      </div>
      <div>
        <img src={brand8} alt='brand' />
      </div>
      

    </div>
  )
}

export default Brand