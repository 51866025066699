import React, { useRef } from 'react';
import './cta.css';
import emailjs from 'emailjs-com';
import toast from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';
import { useTranslation } from "react-i18next";

const CTA = () => {
  const { t } = useTranslation();
  const form = useRef();
  const firstSelect = useRef();
  const secondSelect = useRef();
  const websiteRef = useRef();
  const textareaRef = useRef();
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const companyRef = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    const refreshToast = toast.loading('Refreshing..');

    emailjs.sendForm('service_aqpzmtp', 'template_rxhee2d', form.current, 'tQTn_oV9W-qDm-nv2')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    firstSelect.current.value = '';
    secondSelect.current.value = '';
    websiteRef.current.value = '';
    textareaRef.current.value = '';
    nameRef.current.value = '';
    emailRef.current.value = '';
    phoneRef.current.value = '';
    companyRef.current.value = '';

    toast.success('Request successfully sent', {
      id: refreshToast
    })

  };
  return (
    <div className='dt__cta' id='cta'>
      <div className='dt__cta-content'>
          <h3>{t('cta_h3')}</h3>
          <p>{t('cta_p')}</p>
      </div>
      <form ref={form} onSubmit={sendEmail}>
        <select id="service_needed" defaultValue={''} ref={firstSelect} name="service_needed" placeholder="Select the service you need" required>
          <option value="" disabled hidden>{t('service_needed')}</option>
          <option value={t('data_strategy_consulting')}>{t('data_strategy_consulting')}</option>
          <option value={t('technology_selection')}>{t('technology_selection')}</option>
          <option value={t('project_implementation_services')}>{t('project_implementation_services')}</option>
          <option value={t('project_support_services')}>{t('project_support_services')}</option>
          <option value={t('training_&_enablement')}>{t('training_&_enablement')}</option>
          <option value={t('business_intelligence_consulting')}>{t('business_intelligence_consulting')}</option>
          <option value={t('big_data_consulting')}>{t('big_data_consulting')}</option>
          <option value={t('data_science_consulting')}>{t('data_science_consulting')}</option>
          <option value={t('systems_&_data_integration_consulting')}>{t('systems_&_data_integration_consulting')}</option>
          <option value={t('data_analytics_consulting')}>{t('data_analytics_consulting')}</option>
          <option value={t('data_governance_consulting')}>{t('data_governance_consulting')}</option>
          <option value={t('data_quality_consulting')}>{t('data_quality_consulting')}</option>
          <option value={t('mdm_consulting')}>{t('mdm_consulting')}</option>
          <option value={t('data_warehouse_consulting')}>{t('data_warehouse_consulting')}</option>
          <option value={t('predictive_analytics_consulting')}>{t('predictive_analytics_consulting')}</option>
          <option value={t('data_migration_services')}>{t('data_migration_services')}</option>
          <option value={t('market_research')}>{t('market_research')}</option>
          <option value={t('search_engine_optimization')}>{t('search_engine_optimization')}</option>
          <option value={t('content_marketing')}>{t('content_marketing')}</option>
          <option value={t('social_media_marketing')}>{t('social_media_marketing')}</option>
          <option value={t('pay_per_click_marketing')}>{t('pay_per_click_marketing')}</option>
          <option value={t('affiliate_marketing')}>{t('affiliate_marketing')}</option>
          <option value={t('email_marketing')}>{t('email_marketing')}</option>
          <option value={t('web_design_&_development')}>{t('web_design_&_development')}</option>
          <option value={t('ecommerce_marketing')}>{t('ecommerce_marketing')}</option>
        </select>

        <select id="sector" defaultValue={''} ref={secondSelect} name="sector" placeholder="Select your business sector" required>
          <option value="" disabled hidden>{t('select_sector')}</option>
          <option value={t('banking_finance_insurance')}>{t('banking_finance_insurance')}</option>
          <option value={t('communications_media')}>{t('communications_media')}</option>
          <option value={t('consumer_packaged_goods')}>{t('consumer_packaged_goods')}</option>
          <option value={t('consumer_services_food')}>{t('consumer_services_food')}</option>
          <option value={t('education')}>{t('education')}</option>
          <option value={t('energy')}>{t('energy')}</option>
          <option value={t('healthcare')}>{t('healthcare')}</option>
          <option value={t('manufacturing')}>{t('manufacturing')}</option>
          <option value={t('mining_construction')}>{t('mining_construction')}</option>
          <option value={t('service_consulting')}>{t('service_consulting')}</option>
          <option value={t('retail_wholesale')}>{t('retail_wholesale')}</option>
          <option value={t('tech_software_biotech')}>{t('tech_software_biotech')}</option>
          <option value={t('transportation')}>{t('transportation')}</option>
        </select>
        <input ref={websiteRef} type="website" name='website' placeholder={t('website_input')} required/>
        <textarea ref={textareaRef} type="textarea" name='textarea' placeholder={t('textarea_input')} required></textarea>
        <input ref={nameRef} type="name" name="name" placeholder={t('name')} required/>
        <input ref={emailRef} type="email" name="email" placeholder={t('email')} required/>
        <input ref={phoneRef} type="phone" name="phone" placeholder={t('phone')} required/>
        <input ref={companyRef} type="company" name="company" placeholder={t('company')} required/>
        <Toaster />
        <div className='dt__cta-btn'>
          <button type='Submit'>{t('get_started')}</button>

        </div>
      </form>
    </div>
  )
}

export default CTA