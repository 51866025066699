import React, { useRef } from 'react';
import './header.css';
import people from '../../assets/people.png';
import main from '../../assets/analytics.svg';
import { useTranslation } from "react-i18next";
import emailjs from 'emailjs-com';
import toast from 'react-hot-toast';



function Header() {
  const { t } = useTranslation();
  const form = useRef();
  const newsletterRef = useRef();


  const enlistEmail = (e) => {
    e.preventDefault();
    const refreshToast = toast.loading('Refreshing..');

    emailjs.sendForm('service_aqpzmtp', 'template_cpv0l5n', form.current, 'tQTn_oV9W-qDm-nv2')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    newsletterRef.current.value = '';


    toast.success('Request successfully sent', {
      id: refreshToast
    });

  };

  return (
    <div className='dt__header section__padding' id='home'>
      <div className='dt__header-content'>
        <h1 className='gradient__text'>{t('header_h1')}</h1>
        <p>{t('header_paragraph')}</p>
        <div className='dt__header-content__input'>
          <form ref={form} onSubmit={enlistEmail}>
            <input type="email" ref={newsletterRef} name='newsletter' className='' placeholder={t('your_email')} required />
            <button type="submit">{t('subscribe')}</button>
          </form>
        </div>
        <div className='dt__header-content__people'>
          <img src={people} alt="subscribers"/>
          <p>{t('alot')}</p>

        </div>
      </div>
      <div className='dt__header-image'>
        <img src={main} alt='Main' />
      </div>
    </div>
  );
}

export default Header